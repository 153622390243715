<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header text-center">
          <img class="logo" src="@/assets/logo.png" style="height: 100px" alt="..." />

          <div class="col-12 text-end">

            <div class="text-end mt-5 font-weight-light text-white">
              <h1 style="font-size: 45px !important;">สมาชิก</h1>
            </div>
            <div class="en t2 text-end font-weight-light text-white">
              <h6>MEMBER</h6>
            </div>

          </div>

        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">คำนำหน้า</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="fn" class="form-control  form-control-solid" @change="checkFn($event)"
                    v-model="data_register.fn">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="นาย">นาย</option>
                    <option value="นาง">นาง</option>
                    <option value="นางสาว">นางสาว</option>
                    <option value="อื่นๆ">อื่นๆ</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fn" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="fn_status">
                  <Field type="text" name="fn_other" class="form-control  form-control-solid" placeholder="คำนำหน้า"
                    v-model="data_register.fn_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fn_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ชื่อ</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="firstname" class="form-control  form-control-solid" placeholder="ชื่อ"
                    v-model="data_register.firstname" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="firstname" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">นามสกุล</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="lastname" class="form-control  form-control-solid" placeholder="นามสกุล"
                    v-model="data_register.lastname" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="lastname" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <!-- <div class="row mb-6">
               
                <label class="col-lg-4 col-form-label required fw-bold fs-6">เพศ</label>
             

                
                <div class="col-lg-8 fv-row">
                  <div class="row mt-2">
                    <div class="col-6">
                      <label class="form-check form-check-custom form-check-solid me-9">
                        <Field type="radio" name="gender" class="form-check-input" value="ชาย"
                          v-model="data_register.gender" />
                        <span class="form-check-label gender" for="kt_roles_select_all">ชาย <img :src="iconMale"
                            class="icon" alt="" /></span>
                      </label>
                    </div>
                    <div class="col-6">
                      <label class="form-check form-check-custom form-check-solid me-9">
                        <Field type="radio" name="gender" class="form-check-input" value="หญิง"
                          v-model="data_register.gender" />

                        <span class="form-check-label gender" for="kt_roles_select_all">หญิง <img :src="iconFemale"
                            class="icon" alt="" /></span>
                      </label>
                    </div>
                  </div>
                  <div class="fv-plugins-message-container mt-3">
                    <div class="fv-help-block">
                      <ErrorMessage name="gender" />
                    </div>
                  </div>
                </div>
               
              </div> -->
              <!--end::Input group-->


              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">วัน/เดือน/ปีเกิด</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <div class="row">
                    <div class="col-3">
                      <Field as="select" name="day" class="form-control form-control-solid" v-model="data_register.day">
                        <option value="" disabled selected>วัน</option>
                        <option v-for="index in 31" :key="index" :value="index">
                          {{ index }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="day" />
                        </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <Field as="select" name="month" class="form-control form-control-solid"
                        v-model="data_register.month">
                        <option value="" disabled selected>เดือน</option>
                        <option v-for="n in monthNamesThai" :key="n.id" :value="n.id">
                          {{ n.name }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="month" />
                        </div>
                      </div>
                    </div>

                    <div class="col-4">
                      <Field as="select" name="year" class="form-control form-control-solid" v-model="data_register.year">
                        <option value="" disabled selected>ปี (พ.ศ.)</option>
                        <option v-for="(n, index) in setRange()" :key="index" :value="n">
                          {{ n }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="year" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">เบอร์โทรศัพท์</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="tel" class="form-control  form-control-solid" placeholder="เบอร์โทรศัพท์"
                    v-model="data_register.tel" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="tel" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="submit" class="button">ลงทะเบียน</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
import moment from "moment";
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading
  },
  setup() {
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { Register } = useRegister();
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);
    const data_register = store.getters.getRegister;
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const register = Yup.object().shape({
      fn: Yup.string().required("กรุณาเลือก คำนำหน้า").label("คำนำหน้า"),
      fn_other: Yup.string()
        .when("fn", {
          is: (fn) => fn === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก คำนำหน้า"),
        })
        .label("คำนำหน้า"),
      firstname: Yup.string().required("กรุณากรอก ชื่อ").label("ชื่อ"),
      lastname: Yup.string().required("กรุณากรอก นามสกุล").label("นามสกุล"),
      // gender: Yup.string().required("กรุณาเลือก เพศ").label("เพศ"),
      day: Yup.string().required("กรุณาเลือก วัน"),
      month: Yup.string().required("กรุณาเลือก เดือน"),
      year: Yup.string().required("กรุณาเลือก ปี"),
      tel: Yup.string()
        .required("กรุณากรอกเบอร์โทรศัพท์")
        .matches(phoneRegExp, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
        .min(10, "เบอร์โทรศัพท์ไม่ครบ")
        .max(10, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
      values.birthday = setday(values.day,values.month,values.year);
      store.dispatch(SET_REGISTER, values);
      //router.push({ name: "Register_2" });


      Sconfirm("ยืนยัน การลงทะเบียน", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    function checkFn(event) {
      if (event.target.value === "อื่นๆ") {
        fn_status.value = true;
      } else {
        fn_status.value = false;
      }
    }

    const monthNamesThai = ref([
      { id: "01", name: "มกราคม" },
      { id: "02", name: "กุมภาพันธ์" },
      { id: "03", name: "มีนาคม" },
      { id: "04", name: "เมษายน" },
      { id: "05", name: "พฤษภาคม" },
      { id: "06", name: "มิถุนายน" },
      { id: "07", name: "กรกฎาคม" },
      { id: "08", name: "สิงหาคม" },
      { id: "09", name: "กันยายน" },
      { id: "10", name: "ตุลาคม" },
      { id: "11", name: "พฤษจิกายน" },
      { id: "12", name: "ธันวาคม" },
    ]);

    const setRange = () => {
      let current_year = moment().year() + 543;
      let value = [];
      let i;
      for (i = current_year; i >= 2400; i--) {
        value.push(i);
      }
      return value;
    };
    
    const setday = (day, month, year) => {
      let y = year - 543;
      let m = month;
      let d = day;
      return y + "-" + m + "-" + d;
    };

    return {
      onSubmitRegister,
      register,
      checkFn,
      fn_status,
      data_register,
      monthNamesThai,
      setRange,
      setday,
      isLoading,
      color
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

input[type="radio"] {
  display: none;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
