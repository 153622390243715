import ApiService from "@/service/ApiService";

export default function useRegister() {
  const Register = async (data) => {
    return await ApiService.post("register", data);
  };

  const UpdateUser = async (data) => {
    return await ApiService.put("edit_user/" + data.id_line, data);
  };

  const Add_address = async (data) => {
    return await ApiService.post("add_address", data);
  };

  const Edit_address = async (data) => {
    return await ApiService.post("edit_address", data);
  };

  const Del_address_api = async (id) => {
    return await ApiService.get("del_address/" + id);
  };

  // const Del_address_api = async (data) => {
  //   return await ApiService.post("del_address", data);
  // };

  return {
    Register,
    UpdateUser,
    Add_address,
    Edit_address,
    Del_address_api,
  };
}
