<template>
  <div class="bg-home vh100 pb-5">


    <div class="container">


      <div class="card-header ">
        <div class="d-flex justify-content-center align-items-end" style="height: 100%">
          <div class="bd-highlight" style="margin-right: 5%">
            <img src="@/assets/check.png" style="width: 120%" />
          </div>
          <div class="bd-highlight">
            <normal-text>สมัครสมาชิกสมบูรณ์ !</normal-text>
          </div>
        </div>
      </div>
      <div class="card-body d-flex justify-content-center">
        <div class="box-profile silver_p">
          <img src="@/assets/profile_crow.png" style="
                                          height: 50px;
                                          position: absolute;
                                          margin-top: 2rem;
                                          margin-left: auto;
                                          margin-right: auto;
                                          left: 0;
                                          right: 0;
                                          text-align: center;
                                        " />
          <img :src="ProfireLine.pictureUrl" style="
                                          height: 150px;
                                          margin-top: 4rem;
                                          margin-bottom: 1rem;
                                          position: relative;
                                          border-radius: 50%;
                                          border: 3px solid white;
                                          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                                        " />
          <br />
          <header-text>ยินดีต้อนรับ</header-text>
          <br />
          <normal-text style="padding: 16px;">คุณ {{ Profire.firstname }} {{ Profire.lastname }} </normal-text>
          <div class="footer-box-profile">
            <header-text style="
                                            font-size: 21px;
                                            position: absolute;
                                            text-align: center;
                                            left: 0;
                                            right: 0;
                                            margin-top: 8px;
                                          ">สมาชิกระดับ</header-text>
            <br />
            <normal-text style="font-size: 48px;">{{ Profire.ranks[0].name }}</normal-text>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 text-center">
          <button class="submit-button" @click="go_product" style="background-color: white; color: black; width: 260px">
            สั่งซื้อสินค้าของเรา
          </button>
        </div>
        <br />
        <div class="col-12 text-center mt-3">
          <button @click="go_profile" class="submit-button"
            style="background-color: #79ce32; color: white !important; width: 260px">
            สถานที่จัดส่งของฉัน
          </button>
        </div>

        <div class="col-12 text-center mt-5">

          <img src="@/assets/logo.png" style="width: 10rem" />

        </div>


      </div>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import { defineComponent, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
//import liff from "@line/liff";
export default defineComponent({
  name: "Dashboard",
  setup() {
    const store = inject("store");
    const router = inject("router");


    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    const go_profile = () => {
      router.push({ name: "Profile" });
    };

    const go_product = () => {
      liff.ready.then(() => {
        liff.openWindow({
          url: `line://app/${process.env.VUE_APP_PRODUCT}`,
          external: false,
        });
      });
    };


    return {
      ProfireLine,
      Profire,
      edit,
      go_profile,
      go_product
    };
  },
});

</script>

<style scoped>
.submit-button {
  color: #4E6629 !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  font-size: 22px;
  width: 150px;
  border-radius: 25px;
  border: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 8px 10px 8px 10px;
}

.submit-button:hover,
.submit-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.submit-button:hover {
  transform: translateY(-1px);
}

.submit-button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.card-body {
  background: transparent !important;
  align-items: center !important;
  justify-content: center !important;
}

.card {

  background: transparent !important;
  background-position: center;
  background-size: cover, contain;
  background-repeat: no-repeat;
  height: 300%;
  margin: 0;
  width: auto;
}





.card-header {
  border-bottom: none;
  margin-bottom: 1rem;
  background-color: transparent;
  height: 100px;
  min-height: 100px;
}

.card-body {
  margin-top: 0;
  background-color: transparent;
  flex: unset !important;
  padding: 16px;
}

.card-footer {
  margin-top: 0;
  background-color: transparent !important;
  border: none;
}
</style>
