import { createStore } from "vuex";

import ProfireLine from "@/store/modules/ProfireLine";
import AuthModule from "@/store/modules/AuthModule";
import RegisterModel from "@/store/modules/RegisterModel";
import SendLocation from "@/store/modules/SendLocation";

const store = createStore({
  modules: {
    ProfireLine,
    AuthModule,
    RegisterModel,
    SendLocation
  },
});

export default store;
