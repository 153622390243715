import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Dashboard from "@/views/Dashboard.vue";
import Register from "@/views/register/Register.vue";
import Register_1 from "@/views/register/Register_1.vue";
import Register_2 from "@/views/register/Register_2.vue";
import Profile from "@/views/profile/Profile.vue";
import Edit from "@/views/edit/Edit.vue";
import Edit_1 from "@/views/edit/Edit_1.vue";

import Add_address from "@/views/sendaddress/Add_address.vue";
import Add_address2 from "@/views/sendaddress/Add_address2.vue";
import Edit_address from "@/views/sendaddress/Edit_address.vue";
import Edit_address2 from "@/views/sendaddress/Edit_address2.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/register/step1",
    name: "Register_1",
    component: Register_1,
  },
  {
    path: "/register/step2",
    name: "Register_2",
    component: Register_2,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/edit",
    name: "Edit",
    component: Edit,
  },
  {
    path: "/edit/step1",
    name: "Edit_1",
    component: Edit_1,
  },

  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },

  {
    path: "/",
    name: "Add_address",
    component: Add_address,
  },
  {
    path: "/",
    name: "Add_address2",
    component: Add_address2,
  },

  {
    path: "/",
    name: "Edit_address",
    component: Edit_address,
  },

  {
    path: "/",
    name: "Edit_address2",
    component: Edit_address2,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
