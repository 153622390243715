<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">


        <div class="card-header text-center">
          <img class="logo" src="@/assets/logo.png" style="height: 100px" alt="..." />

          <div class="col-12 text-end">

            <div class="text-end mt-5 font-weight-light text-white">
              <h1 style="font-size: 35px !important;">ที่อยู่จัดส่ง</h1>
            </div>
            <div class="en t2 text-end font-weight-light text-white">
              <h6>ADDRESS</h6>
            </div>

          </div>

        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <div class="row mb-1">
                <div id="mapid" style="height: 450px"></div>

                <Field type="hidden" name="latitude" v-model="latitude" />
                <Field type="hidden" name="longitude" v-model="longitude" />
                <div>
                  <div class="myl" @click="mylo">
                    ตำแหน่งของฉัน<i class="bi bi-geo-fill" style="margin-left: 10px"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer white">
            <div class="row mb-2 justify-content-center ps-4 pe-4">
              <button type="button" @click="back" class="button btn-back mt-2 me-3" style="color:">
                ย้อนกลับ
              </button>
              <button type="submit" class="button mt-2">
                เพิ่มที่อยู่
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, ref, onMounted, inject, computed } from "vue";
//import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { SET_SEND_LOCATION, SET_EDIT_LOCATION } from "@/store/modules/SendLocation";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
import json from "@/service/DataStore.json";
import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
import leaflet from "leaflet";
export default defineComponent({
  name: "Edit_Address2",
  components: {
    Field,
    Form,
    Loading,
  },
  setup() {
    let marker = ref([]);
    let mymap;
    const store = inject("store");
    const router = inject("router");
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { Edit_address } = useRegister();
    const items = ref(json);
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const data_send_location = store.getters.getEditlocation;

    let latitude = ref(store.getters.getEditlocation.latitude);
    let longitude = ref(store.getters.getEditlocation.longitude);

    // const newIcon = leaflet.icon({
    //   iconUrl: `https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png`,
    //   shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
    //   iconSize: [25, 41],
    //   iconAnchor: [12, 41],
    //   popupAnchor: [1, -34],
    //   shadowSize: [41, 41]
    // });

    const markerHtmlStyles = `
    background-color: ${color.value};
    width: 3rem;
    height: 3rem;
    display: block;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);`


    const circleStyles = `
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: white;
    `;

    const newIcon = leaflet.divIcon({
      className: "my-custom-pin",
      iconAnchor: [0, 24],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}"><div style="${circleStyles}"></div></span>`
    });



    const register = Yup.object().shape({
      latitude: Yup.string(),
      longitude: Yup.string(),
    });

    const uniqueProvince = computed(() => {
      return items.value.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.province]: current,
        });
      }, {});
    });

    function setLocation() {
      if (!navigator.geolocation) {
        alert("Geolocation is not supported by your browser");
      }
    }

    const back = () => {
      router.push({ name: "Edit_address" });
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
      setLocation();

      mymap = leaflet

        .map("mapid")
        .setView([store.getters.getEditlocation.latitude, store.getters.getEditlocation.longitude], 15);
      leaflet
        .tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmFjb3MyNSIsImEiOiJja3Y2a2pyNmk5YWZxMzBxanVrNno3NnE0In0.okOhpHHfbDXptqipC8iWTg",
          {
            attribution: "bacos25",
            maxZoom: 18,
            id: "mapbox/streets-v11",
            accessToken:
              "pk.eyJ1IjoiYmFjb3MyNSIsImEiOiJja3Y2a2pyNmk5YWZxMzBxanVrNno3NnE0In0.okOhpHHfbDXptqipC8iWTg",
          }
        )
        .addTo(mymap);

      marker.value = leaflet.marker(
        [store.getters.getEditlocation.latitude, store.getters.getEditlocation.longitude],
        {
          icon: newIcon,
          draggable: true,
        }
      );



      marker.value.on("dragend", function (event) {
        latitude.value = event.target._latlng.lat;
        longitude.value = event.target._latlng.lng;
      });



      marker.value.addTo(mymap);


    });





    const mylo = () => {

      navigator.geolocation.getCurrentPosition((position) => {
        let newLatLng = new leaflet.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        marker.value.setLatLng(newLatLng);
        latitude.value = position.coords.latitude;
        longitude.value = position.coords.longitude;

        mymap.panTo(newLatLng);
      });
    };

    const onSubmitRegister = (values) => {
      values.id_line = store.getters.getAccessToken.accesstoken;
      store.dispatch(SET_EDIT_LOCATION, values);


      console.log(store.getters.getEditlocation);
      Sconfirm("ยืนยัน แก้ไขที่อยู่จัดส่ง", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;


          await Edit_address(store.getters.getEditlocation)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                store.commit("clearSendlocation");
                store.dispatch(SET_SEND_LOCATION, result.data.data);

                console.log( result.data.data);
                SconfirmNc("แก้ไขที่อยู่จัดส่ง สำเร็จ", "success", "ตกลง").then(() => {
                  router.push({ name: "Profile" });

                  // if (store.getters.getUrlCallBack.id) {
                  //   liff.ready.then(() => {
                  //     liff.openWindow({
                  //       url: store.getters.getUrlCallBack.id,
                  //       external: false,
                  //     });
                  //   });
                  // } else {
                  //   router.push({ name: "Home" });
                  // }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    return {
      back,
      onSubmitRegister,
      register,
      data_send_location,
      isLoading,
      color,
      uniqueProvince,
      setLocation,
      mylo,
      latitude,
      longitude
    };
  },
});
</script>

<style scoped>
.card-body {
  padding: 0;
}

.myl {
  line-height: 40px;
  text-align: center;
  border: 1px solid #ccc;
  font-size: 17px;
}

@import "https://unpkg.com/leaflet@1.7.1/dist/leaflet.css";
</style>
