<template>
  <div class="bg-home vh100">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="container">
      <div class="card-header text-center">


        <div class="row">
          <div class="col-6 text-start">
            <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          </div>
          <div class="col-6 text-end">
            <div class="mt-4 font-weight-light text-white">
              <h1 style="font-size: 45px !important;">สมาชิก</h1>
            </div>
            <div class="en t2 font-weight-light text-white">
              <h6>MEMBER</h6>
            </div>
          </div>

        </div>

      </div>

      <div class="card-body">
        <div class="row text-end text-white"><span @click="edit" class="title-text"
            style="font-size: 18px !important;">แก้ไขโปรไฟล์ ></span>
        </div>
        <div class="row justify-content-center align-items-center">
          <div class="col-11">
            <div class="box-profile silver_p">
              <img src="@/assets/profile_crow.png"
                style="
                                                                                                                                                                        height: 50px;
                                                                                                                                                                        position: absolute;
                                                                                                                                                                        margin-top: 2rem;
                                                                                                                                                                        margin-left: auto;
                                                                                                                                                                        margin-right: auto;
                                                                                                                                                                        left: 0;
                                                                                                                                                                        right: 0;
                                                                                                                                                                        text-align: center;
                                                                                                                                                                      " />
              <img :src="ProfireLine.pictureUrl"
                style="
                                                                                                                                                                        height: 150px;
                                                                                                                                                                        margin-top: 4rem;
                                                                                                                                                                        margin-bottom: 3rem;
                                                                                                                                                                        position: relative;
                                                                                                                                                                        border-radius: 50%;
                                                                                                                                                                        border: 3px solid white;
                                                                                                                                                                        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                                                                                                                                                                      " />

            </div>
          </div>
        </div>

      </div>
      <div class="container text-center justify-content-center align-items-center d-flex">

        <div class="font-weight-light text-white">
          <p style="font-size: 27px;">คุณ {{ Profire.firstname }} {{ Profire.lastname }} </p>
          <p style="font-size: 22px;">เบอร์โทรศัพท์ {{ Profire.tel }} </p>

          <div class="rank_box mt-4 ps-4 pe-4 align-items-centerjustify-content-center">

            <p style="font-size: 27px; color:#BED630">ระดับสมาชิก : {{ Profire.ranks[0].name }} </p>
          </div>

        </div>

      </div>
    </div>
    <div class="address-box  mt-3 pb-3 mb-5">

      <div class="address-box-header">
        <div class="row justify-content-between">
          <div class="col-6 font-weight-light">
            <p style="font-size: 18px;">ที่อยู่สำหรับจัดส่ง</p>
          </div>
          <div class="col-6 font-weight-light text-end text-white">
            <p @click="Add_address()" style="font-size: 18px;">เพิ่มที่อยู่จัดส่ง ></p>
          </div>
        </div>
      </div>


      <div class="address-box-body pb-2" v-if='SendLocation === null || JSON.stringify(SendLocation) === "{}"'>

        <h5 class="text-white text-center">ไม่พบข้อมูล</h5>

      </div>


      <div class="address-box-body pb-2" v-else>
        <div class="container" v-for="(item, key) in  SendLocation " :key="key.id" :value="key.id">

          <!-- ro justify-content-between fwont-weight-normal ps-4 pe-4 mb-2 -->


          <div class="row mb-2" v-if="item.approve === 0">
            <div class="col-6">
              <i class="bi bi-exclamation-circle-fill" style="font-size: 18px; color:#FEFD30;"></i>
              <span style="font-size: 18px; padding-left: 5px;">{{ item.fullname }}</span>



            </div>
            <div class="col-6 text-end">
              <span style="font-size: 18px; margin-right: 5px">{{ item.tel }}</span>
            </div>


            <div class="col-8 position-relative mt-2" style="font-size: 16px;">

              สถานะ : รอการพิจารณา


            </div>

            <div class="col-4 text-end pt-4">
              <a @click="go_map(item.latitude, item.longitude);"><img src="~@/assets/map_bt.png"
                  style="width: 110px;" /></a>

            </div>
          </div>


          <div class="row mb-2" v-if=" item.approve == 1 ">
            <div class="col-6">
              <img src="@/assets/check.png" style="width: 20px; margin-bottom : 8px;" />
              <span style="font-size: 18px; padding-left: 5px;">{{ item.fullname }}</span>



            </div>
            <div class="col-6 text-end">
              <span style="font-size: 18px; margin-right: 5px">{{ item.tel }}</span>
              <i class="bi bi-pencil-fill me-2" @click=" edit_location(SendLocation[key]) "></i>
              <i class="bi bi-x-circle-fill" @click=" Del_address(item.id) " style="color: #DB0125;"></i>
            </div>


            <div class="col-8 position-relative mt-2" style="font-size: 16px;">
              สถานะ : ผ่านการตรวจสอบ <br>
              ค่าส่งสินค้า : {{ item.price }} บาท  <br>
              หมายเหตุ : {{ item.remark }}

            </div>

            <div class="col-4 text-end pt-4">
              <a @click=" go_map(item.latitude, item.longitude); "><img src="~@/assets/map_bt.png"
                  style="width: 110px;" /></a>

            </div>
          </div>
          <!-- v-if="item.approve == 3" -->
          <div class="row mb-2" v-if=" item.approve == 2 ">
            <div class="col-6">
              <i class="bi bi-x-circle-fill" style="font-size: 18px; color:#DB0125;"></i>
              <span style="font-size: 18px; padding-left: 5px;">{{ item.fullname }}</span>



            </div>
            <div class="col-6 text-end">
              <span style="font-size: 18px; margin-right: 5px">{{ item.tel }}</span>
              <i class="bi bi-pencil-fill me-2" @click=" edit_location(SendLocation[key]) "></i>
              <i class="bi bi-x-circle-fill" @click=" Del_address(item.id) " style="color: #DB0125;"></i>
            </div>


            <div class="col-8 position-relative mt-2" style="font-size: 16px;">
              สถานะ : ไม่อนุมัติ <br>
              หมายเหตุ : {{ item.remark }}


            </div>

            <div class="col-4 text-end pt-4">
              <a @click=" go_map(item.latitude, item.longitude); "><img src="~@/assets/map_bt.png"
                  style="width: 110px;" /></a>

            </div>
          </div>







          <!-- 
            <div class="col-6">

              <img src="@/assets/check.png" style="width: 20px; margin-bottom : 8px;" /><span
                style="font-size: 18px; padding-left: 5px;">{{ item.fullname }}</span>



            </div>
            <div class="col-6 text-end">
              <span style="font-size: 18px; margin-right: 5px">{{ item.tel }}</span>
              <i class="bi bi-pencil-fill me-2" @click="edit_location(SendLocation[key])"></i>
              <i class="bi bi-x-circle-fill" @click="Del_address(item.id)" style="color: #DB0125;"></i>

            </div>

            <div class="col-8 position-relative mt-2" style="font-size: 16px;">

              {{ item.numberhome }} ม.{{ item.moo }} {{ item.alleyway }} {{ item.district }} {{ item.amphoe }}
              {{ item.province }} {{ item.zipcode }}


            </div>

            <div class="col-4 text-end pt-4">
              <a @click="go_map(item.latitude, item.longitude);"><img src="~@/assets/map_bt.png"
                  style="width: 100%;" /></a>

            </div> -->














        </div>



      </div>





    </div>

  </div>
  <div class="card-footer border-0 d-flex flex-wrap justify-content-center align-items-end white p-3">




    <div class="font-weight-light" style="color:#4E5E38" @click=" go_history_product ">
      <h5>ประวัติการสั่งซื้อสินค้า & ติดตามการจัดส่ง</h5>
    </div>


  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import useSweetalert from "@/service/sweetalert2";
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import liff from "@line/liff";
import { SET_SEND_LOCATION, SET_EDIT_LOCATION } from "@/store/modules/SendLocation";
import useRegister from "@/service/api/register";
export default defineComponent({
  name: "Profile",
  components: {
    Loading,
  },
  setup() {
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);
    const { Del_address_api } = useRegister();
    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;

    const color = '#FFFFFF';

    const SendLocation = ref(null);

    let isLoading = ref(false);

    onMounted(() => {
      SendLocation.value = store.getters.getSendlocation;
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    const edit_location = (location_data) => {
      console.log(location_data);
      store.dispatch(SET_EDIT_LOCATION, location_data);
      router.push({ name: "Edit_address" });
    };


    const go_history_product = () => {
      liff.ready.then(() => {
        liff.openWindow({
          url: `line://app/${process.env.VUE_APP_PRODUCT}`,
          external: false,
        });
      });
    };




    const Add_address = () => {


      //  Object.keys(SendLocation.value).length

      // console.log(Object.keys(SendLocation.value).length);
      if (Object.keys(SendLocation.value).length >= 3) {
        SconfirmNc("ไม่สามารถเพิ่มที่อยู่ได้เนื่องจากเกิน จำนวนที่ระบบกำหนด", "error", "ตกลง");
      } else {
        router.push({ name: "Add_address" });
      }

    };

    const Del_address = (id) => {


      // let send_data = [];
      // send_data.id = id;
      // send_data.id_line = store.getters.getAccessToken.accesstoken;


      // console.log(send_data);
      Sconfirm("ยืนยัน ลบที่อยู่จัดส่ง", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Del_address_api(id)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                store.commit("clearSendlocation");
                store.dispatch(SET_SEND_LOCATION, result.data.data);
                SendLocation.value = store.getters.getSendlocation;
                //console.log(result.data.data);
                SconfirmNc("ลบที่อยู่จัดส่ง สำเร็จ", "success", "ตกลง").then(() => {
                  router.push({ name: "Profile" });
                  SendLocation.value = store.getters.getSendlocation;
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });



    };

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    const go_map = (lat, lng) => {
      //return `http://maps.google.com/maps?q=${lat},${lng}`;
      window.open(`http://maps.google.com/maps?q=${lat},${lng}`, '_blank').focus();
    };

    return {
      fn_status,
      ProfireLine,
      Profire,
      edit,
      close,
      go_map,
      Add_address,
      SendLocation,
      Del_address,
      edit_location,
      go_history_product,
      isLoading,
      color
    };
  },
});

</script>

<style scoped>
.card-header {
  border-bottom: none;
  margin-bottom: 1rem;
  background-color: transparent;
  height: 100px;
  min-height: 100px;
}

.rank_box {
  border: none;
  border-radius: 25px;
  background-color: #707070;
  padding: 8px 8px 1px 8px;
}

.address-box {
  background-color: #BED630;
  min-height: 300px;
  overflow: scroll;
  padding: 16px;

}

.address-box-header {
  position: relative;
}

.address-box-body {
  position: relative;
  min-height: 250px;
  height: 250px;
  overflow-y: scroll;

}
</style>
